<template>
  <div>
    <b-sidebar
      id="sidebarAutomacao"
      @shown="openSidebar"
      right
      shadow
      backdrop
      ref="sidebarAutomacao"
    >
      <div class="p-4" v-if="!loading">
        <h1 class="titulo mb-1">
          {{ !info ? "Criar automação" : "Atualizar automação" }}
        </h1>
        <div>
          <label class="mt-4">Quando?</label>
          <multiselect
            v-model="selectedQuando"
            id="ajax"
            label="text"
            track-by="value"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            open-direction="bottom"
            :options="optionsQuando"
            :multiple="false"
            @select="resetOptions"
            :searchable="false"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :show-no-results="true"
            :hide-selected="true"
            :watch="true"
          >
          </multiselect>
        </div>

        <div v-if="!selectedQuando.value == ''">
          <label class="mt-4"> Qual Tag?</label>
          <BaseSelect
            :selectModel="selectedTag"
            trackname="name"
            track-by="id"
            :array="tags"
            :multiple="false"
            :taggable="true"
            class=""
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            specificType="tags"
            @search="debounceTags($event)"
            :loading="loadingTags"
            @callback="getTags(++currentPaginationTags)"
            @change="selectedTag = $event"
            :selectEvent="true"
            :watch="true"
            ><span slot="noResult">Nenhuma Tag encontrada.</span>
          </BaseSelect>
        </div>

        <div v-if="selectedTag">
          <label class="mt-4">O que fazer?</label>
          <multiselect
            v-model="selectedAcao"
            id="ajax"
            label="text"
            track-by="value"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            open-direction="bottom"
            :options="getOptionsAcoes"
            @select="selectAction"
            :multiple="false"
            :searchable="false"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :show-no-results="true"
            :hide-selected="true"
            :watch="true"
          >
          </multiselect>
        </div>

        <div v-if="selectedAcao.value == 'associarCRM' || selectedAcao.value == 'desassociarCRM'">
          <label class="mt-4">Qual CRM?</label>
          <BaseSelect
            :selectModel="selectPipeline"
            id="ajax"
            trackname="title"
            track-by="id"
            placeholder="Pesquise o CRM"
            :array="pipelines"
            :multiple="false"
            @search="debounceCrm($event)"
            @callback="fetchPipelines($event)"
            @change="pipeline = $event"
            @select="selectCRM"
            :watch="true"
          >
            <span slot="noOptions">Pesquise pelo crm</span>

            <span slot="noResult">Oops! Nenhum CRM encontrado.</span>
          </BaseSelect>
          <div v-if="selectedAcao.value == 'desassociarCRM'">
            <label class="mt-4">Deseja remover a tag?</label>
              <b-form-checkbox
              v-model="removeTag"
              name="is_main"
              style="z-index:0;"
              size="lg"
              switch
            >
              <p class="info-checkbox" style="width: 0px">
                {{ removeTag ? " Sim " : "Não" }}
              </p>
            </b-form-checkbox>
          </div>
          <div v-if="selectedAcao.value != 'desassociarCRM'">
            <label class="mt-4">Qual Board?</label>

          <multiselect
            v-model="board"
            id="ajax"
            label="name"
            :placeholder="
              pipeline ? 'Selecione um board' : 'Selecione um CRM antes'
            "
            :disabled="!pipeline"
            track-by="id"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            open-direction="bottom"
            :options="boards"
            :multiple="false"
            :searchable="true"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :show-no-results="true"
            :hide-selected="true"
            @select="last_step = true"         
          >
            <span slot="noOptions">Pesquise pelo board</span>

              <span slot="noResult">Oops! Nenhum board encontrado.</span>
            </multiselect>
          </div>
        </div>

        <div
          v-if="
            selectedAcao.value == 'cadastroForm' ||
            selectedAcao.value == 'removeForm'
          "
        >
          <div class="add-link-body mt-3">
            <label> Qual formulário? </label>
            <router-link
              v-if="
                !forms.length && this.stops.includes('form') && verificUserLevel
              "
              :to="{ name: 'CampanhasLista' }"
              class="add-link"
              >Deseja criar um formulário?</router-link
            >
          </div>
          <BaseSelect
            v-if="showSelectForm"
            :clear="clear"
            ref="selectForm"
            :selectModel="selectedForm"
            track-by="id"
            trackname="title"
            :array="forms"
            :multiple="false"
            :taggable="true"
            class=""
            :clear-on-select="false"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            @search="debounceForms($event)"
            :loading="loadingForms"
            :watch="true"
            @callback="getForms($event)"
            @change="selectForm($event), last_step = true"
            ><span slot="noResult">Nenhum formulário encontrado.</span>
          </BaseSelect>
        </div>

        <div v-if="selectedAcao.value == 'webhook'">
          <label class="mt-4">Qual Url?</label>
          <input class="w-100" type="text" name="url" id="url" v-model="url" />
          <p class="danger" v-if="!isUrl">Esta URL não é válida</p>
        </div>

        <div v-if="selectedAcao.value == 'associarProposta'">
          <div class="add-link-body">
            <label class="mt-4">Qual produto?</label>
            <router-link
              v-if="!products.length && loadingProducts == false"
              :to="{ name: 'ConfiguracoesSistema' }"
              class="add-link"
              >Deseja criar um produto?</router-link
            >
          </div>
          <BaseSelect
            open-direction="bottom"
            :selectModel="selectedProduto"
            trackname="name"
            track-by="id"
            :array="products"
            specificType="products"
            :multiple="false"
            :taggable="true"
            class=""
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :allow-empty="false"
            :timeout="500"
            v-if="showComponentProduct"
            @search="debounceProduct($event)"
            :loading="loadingProducts"
            @callback="getProduct($event)"
            @change="changeProduct($event), last_step = true"
            ><span slot="noResult">Nenhum produto encontrado.</span>
          </BaseSelect>
          <div v-if="selectedProduto">
            <div class="d-flex justify-content-between">
              <label class="mt-4">Desconto ({{ descValue }})</label>
            </div>
            <input
              style="margin-bottom: 0 !important; width: 100%;"
              type="range"
              max="100"
              min="0"
              step="1"
              v-model="valorPorcentagem"
              @input="ajustValues(true)"
            />
            <label class="mt-4">Valor final</label>
            <div>
              <money
                id="valor"
                v-model="valor"
                v-bind="money"
                class="w-100"
                @input="ajustValues()"
              ></money>
            </div>
          </div>
        </div>

        <div v-if="selectedAcao.value == 'associarTag'">
          <label class="mt-4"> Qual tag associar?</label>
          <BaseSelect
            :selectModel="selectedAssocTag"
            trackname="name"
            track-by="id"
            :array="getArrayTags"
            :multiple="false"
            :taggable="true"
            class=""
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            @search="debounceTags($event)"
            :searchable="true"
            :loading="loadingTags"
            @callback="getTags(++currentPaginationTags)"
            @change="selectedAssocTag = $event, last_step = true"
            :selectEvent="true"
            :watch="true"
            ><span slot="noResult">Nenhuma Tag encontrada.</span>
          </BaseSelect>
        </div>

        <div v-if="selectedAcao && selectedAcao.value">
          <label class="mt-4">Quando executar?</label>
          <multiselect
            v-model="selectedAutomationType"
            id="ajax"
            placeholder=""
            track-by="type"
            label="label"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            open-direction="bottom"
            :options="automationTypes"
            :multiple="false"
            :searchable="true"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :show-no-results="true"
            :hide-selected="true"
          >
            <span slot="noOptions">Pesquise pelo board</span>

            <span slot="noResult">Oops! Nenhum board encontrado.</span>
          </multiselect>

          <div class="separete" v-if="selectedAutomationType.type === 'schedule'">
            <div class="input-mensage">
              <input
                style="width: 100%; margin-bottom: 15px !important"
                placeholder="Ex: 345"
                v-mask="'###'"
                v-model="days"
              />
              <span class="day-temp">Dias após</span>
            </div>
            <div class="input-mensage">
              <input
                style="width: 100%; margin-bottom: 15px !important"
                placeholder="Ex: 03:45"
                v-mask="'##:##'"
                v-model="time"
              />
              <span class="day-temp">{{!+days ? 'Tempo após inscrição' : 'Hora do dia'}}</span>
            </div>
          </div>
        </div>
        <div v-if="last_step">
          <BaseButton
          @click="onSubmit"
          class="btn-automacao"
          variant="outline-primary"
          :disabled="loading"
          >
          Salvar automação
        </BaseButton>
      </div>
      </div>

      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import BaseButton from "@/components/BaseButton";
import { Money } from "v-money";
import TagAutomationService from "@/services/resources/TagAutomationService";
import TagService from "@/services/resources/TagService";
import FormService from "@/services/resources/FormService";
import { setupDB, getCachedData, cacheData } from "@/store/indexedDB.js";

const serviceForm = FormService.build();
const serviceTag = TagService.build();
const serviceAutomacoes = TagAutomationService.build();

import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();

import ConfigProdutosService from "@/services/resources/ConfigProdutosService";
const serviceProduct = ConfigProdutosService.build();

import _ from "lodash";

export default {
  name: "SidebarAutomacao",
  data() {
    return {
      //tags
      loadingTags: false,
      stopTags: false,
      currentPaginationTags: 0,
      removeTag:true,

      //forms
      loadingForms: false,
      searchForm: "",

      //products
      loadingProducts: false,
      searchProducts: "",
      stopProduct: false,
      showComponentProduct: true,

      //assoc tags
      selectedAssocTag: null,

      loading: false,
      tags: [],
      forms: [],
      stops: [],
      formulario: [],
      products: [],
      selectedQuando: { value: "", text: "" },
      optionsQuando: [
        { value: "entrar", text: "Entrar" },
        { value: "sair", text: "Sair" },
      ],
      selectedTag: null,
      selectedAcao: { value: "", text: "" },
      optionsAcoes: [
        { value: "cadastroForm", text: "Cadastrar em um formulário" },
        { value: "removeForm", text: "Remover de um formulário" },
        { value: "webhook", text: "Enviar Webhook" },
        { value: "associarProposta", text: "Associar proposta" },
        { value: "associarCRM", text: "Associar CRM" },
        { value: "associarTag", text: "Associar tag" },
      ],
      selectedForm: null,
      url: null,
      isUrl: true,
      clear: false,
      produtos: [],
      selectedProduto: null,
      valor: 0,
      valorPorcentagem: 0,
      id: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: " ",
        precision: 2,
        masked: false,
      },
      //CRM 
      pipeline: null,
      pipelines: [],
      board: null,
      boards: [],

      stops: [],
      out_arguments: null,
      showSelectForm: true,
      //agendamento e automação
      days: null,
      time: null,
      automationTypes: [
        {
          type: "immediately",
          label: "Imediatamente",
        },
        {
          type: "schedule",
          label: "Personalizado"
        }
      ],
      selectedAutomationType: {
        type: "immediately",
        label: "Imediatamente"
      },
      
      last_step: false
    };
  },
  components: {
    Multiselect,
    BaseButton,
    Money,
  },
  props: ["info", "fetched_crms"],
  methods: {
    async selectForm(event){
      if(this.validSelectForm(event)){
        this.selectedForm = event
      }else{
        this.showSelectForm = false
        await this.$nextTick()
        this.showSelectForm = true
      }

    },
    validSelectForm(event){
      let idTagForm;
      let idTagBord;
      let valid;
      let option = this.selectedQuando.value == "entrar" ? "tag_unsubs" : "tag_subs"

      if(event){
        idTagForm = (event.metas.find((meta)=> {
          return meta.meta_key == option
        }).meta_value).split(',').map(id => parseInt(id.trim(), 10))

        if(option == "tag_subs"){

          let addCRM = event.metas.find((meta)=>{
            return meta.meta_key == "add_pipeline"
          })
          
          if(addCRM && addCRM.meta_value == 'true'){
            idTagBord = parseInt( event.metas.find((meta)=> {
              return meta.meta_key == "board_id"
            }).meta_value)
          }
        }
      }
      if(idTagForm){
        valid = idTagForm.find((id)=>{
          return id == this.selectedTag.id
        })
      }
      if(valid || idTagBord == this.selectedTag.id){
        this.$grToast.toast("Não é possível selecionar o formulário que contém a mesma tag da automação.", {
          title: "Automação de tags",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return false
      }
      return true
    },
    selectAction(){
      if(this.selectedAcao.value == 'associarCRM'){
        this.last_step = false
      }else if(this.selectedAcao.value == 'webhook'){
        return this.last_step = true;
      }
    },
    selectCRM(){
      if(this.selectedAcao.value == 'desassociarCRM'){
        return this.last_step = true;
      }
      this.last_step = false;
    },
    resetOptions(){
      this.last_step = false;
      this.selectedTag =  null;
      this.selectedAcao = { value: "", text: "" };
    },
    changeProduct(ev) {
      this.selectedProduto = ev
      this.valorPorcentagem = 0
      this.valor = this.selectedProduto.product_value
    },
    ajustValues(isFromDiscount) {
      if (isFromDiscount === true) {
        if (this.valorPorcentagem == 0) {
          this.valor = this.selectedProduto.product_value;
          return;
        }
        this.valor =
          (this.selectedProduto.product_value * (100 - this.valorPorcentagem)) /
          100;
      } else {
        let newValorPorcentagem =
          100 - (this.valor * 100) / this.selectedProduto.product_value;
        if (newValorPorcentagem > 100) {
          this.valorPorcentagem = 100;
          this.valor = 0;
        } else if (newValorPorcentagem < 0) {
          this.valorPorcentagem = 0;
          this.valor = this.selectedProduto.product_value;
        } else {
          this.valorPorcentagem = Math.round(newValorPorcentagem * 100) / 100;
        }
      }
    },

    debounceTags: _.debounce(function (query) {
      this.currentPaginationTags = 1;
      if (query === "") {
        this.loadingTags = false;
      } else {
        this.stopTags = false;
        this.getTags(1, query);
      }
    }, 500),

    getTags(page = 1, query = "") {
      if (this.stopTags == true) {
        return;
      }
      var data = {
        id: `list?page=${page}&order_by=name&order=ASC&search=${query}`,
      };

      serviceTag
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopTags = true;
          }
          this.tags = this.tags.concat(resp.data);
          this.tags = this.tags.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingTags = false;
        });
    },
    debounceProduct: _.debounce(function (query) {
      if (query === "") {
        this.resetComponentProduct();
        this.loadingProducts = false;
      } else {
        this.searchProducts = query;
        this.products = [];
        this.getProduct(1, query);
      }
    }, 500),
    resetComponentProduct() {
      this.searchProducts = "";
      this.stopProduct = false;
      this.showComponentProduct = false;
      this.$nextTick(() => {
        this.showComponentProduct = true;
      });
    },
    getProduct(page = 1, query = "") {
      if (this.stopProduct == true) {
        return;
      }

      this.loadingProducts = true;

      var data = {
        id: `list?page=${page}&order_by=name&order=ASC&search=${query}&status=active`,
      };

      serviceProduct
        .createId(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stopProduct = true;
          }

          this.products = this.products.concat(resp.data);
          this.products = this.products.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((err) => {})
        .finally(() => {
          this.loadingProducts = false;
        });
    },

    debounceForms: _.debounce(function (query) {
      if (query === "") {
        this.loadingForms = false;
      } else {
        this.searchForm = query;
        this.getForms(1, query);
      }
    }, 500),

    async getForms(page = 1, query = "") {
      if (this.stops.includes("form")) {
        return;
      }
      var data = {
        id: `?page=${page}&order_by=title&order=ASC&status=active&search=${query}`,
      };
      await serviceForm
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stops.push("form");
          }
          this.forms = this.forms.concat(resp.data);
          this.forms = this.forms.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingForms = false;
        });
    },

    async openSidebar() {
      this.loading = true;
      await this.getProduct(1);
      await this.getTags();

      if (this.info == null) {
        // this.tags = this.puxarTags;
        await this.getForms();
        this.selectedQuando = { value: "", text: "" };
        this.selectedTag = null;
        this.selectedAcao = { value: "", text: "" };
        this.selectedForm = null;
        this.selectedProduto = null;
        this.valor = 0;
        this.url = null;
        this.selectedAssocTag = null;
        this.days = null;
        this.time = null;
        this.last_step = false;
        this.selectedAutomationType = {type: "immediately", label: "Imediatamente"}
      } else {
        this.tags = this.tags;
        await this.getForms();

        if(this.info.selectedTag){
          this.selectedTag = this.info.tag_automation;
        }else{
          serviceTag.read('show/'+this.info.in_id).then(r => {
            this.selectedTag = r;
          });
        }
        
        this.selectedQuando = {
          value: this.info.in_action == "assoc" ? "entrar" : "sair",
          text: this.info.in_action == "assoc" ? "Entrar" : "Sair",
        };
        this.id = this.info.id;
        
        this.selectedAutomationType = this.automationTypes.find(x => x.type === this.info.type);

        if(this.info.schedule_date){
          const schedule_date = this.info.schedule_date.split('|');
          this.days = schedule_date[0];
          this.time = schedule_date[1];
        }
        switch (this.info.out_action) {
          case "Form::assoc":
            this.selectedForm = this.forms.find(
              (x) => x.id == this.info.out_id
            );
            this.selectedAcao = {
              value: "cadastroForm",
              text: "Cadastrar em um formulário",
            };
            break;
          case "Form::desassoc":
            this.selectedForm = this.forms.find(
              (x) => x.id == this.info.out_id
            );
            this.selectedAcao = {
              value: "removeForm",
              text: "Remover de um formulário",
            };
            break;
          case "Webhook::tagAutomation":
            this.selectedAcao = { value: "webhook", text: "Enviar Webhook" };
            this.url = JSON.parse(this.info.out_arguments);
            this.selectedForm = 0;
            break;
          case "Produc::proposal":
            this.selectedAcao = {
              value: "associarProposta",
              text: "Associar proposta",
            };
            var id_produto = JSON.parse(this.info.out_arguments).product_id;
            this.selectedProduto = this.products.find(
              (x) => x.id == id_produto
            );
            this.valor = JSON.parse(this.info.out_arguments).net_value;
            this.valorPorcentagem =
              (this.valor * 100) /
              parseFloat(this.selectedProduto.product_value);
            break;
          case "CRM::assoc":
            this.selectedAcao = {
              value: "associarCRM",
              text: "Associar CRM",
            };

            this.out_arguments = JSON.parse(this.info.out_arguments);

            servicePipeline
              .read({ id: this.out_arguments.pipeline_id })
              .then(async (resp) => {
                this.pipeline = resp;
              });

            break;
          case "CRM::desassoc":
          this.selectedAcao = {
            value: "desassociarCRM",
            text: "Desassociar CRM",
          };

          this.out_arguments = JSON.parse(this.info.out_arguments);

          servicePipeline
            .read({ id: this.out_arguments.pipeline_id })
            .then(async (resp) => {
              this.pipeline = resp;
              this.removeTag = this.out_arguments.remove_tag;
            });

          break;
          case "Tag::assoc":
            this.selectedAcao = this.optionsAcoes.find(x => x.value === "associarTag");
            this.selectedAssocTag = this.info.selectedTag;
              break;
        }
        this.loading = false;
        this.last_step = true;
        return;
      }

      this.loading = false;
      this.board = null;
      this.boards = [];
      this.pipeline = null;
      this.pipelines = [];
      this.stops = [];
    },
    async selectedPipe(e) {
      this.boards = [];
      this.board = null;
      if (e.id != null) {
        var tags_selecteds = e.tags;
        var tags = [];
        for (let i = 0; i < tags_selecteds.length; i++) {
          const element = tags_selecteds[i];
          await serviceTag.read(`/show/${element}`).then((resp) => {
            tags.push(resp);
          });
        }

        this.boards = tags;

        if (this.info && this.out_arguments) {
          this.board = this.boards.find(
            (x) => x.id == this.out_arguments.board_id
          );
        }
      }
    },
    urlValidate(url) {
      const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      this.isUrl = regex.test(url);
    },
    onSubmit() {
      if(this.selectedAutomationType && this.selectedAutomationType.type === 'schedule' && (!this.days || !this.time)){
        this.$grToast.toast("Por favor, configure quando a automação deve ser executada", {
          title: "Automação de tags",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.loading = true
      let data = {};
      if(!this.validSelectForm(this.selectedForm)){
        this.loading = false
        return
      }
      data.in_action =
        this.selectedQuando.value == "entrar" ? "assoc" : "desassoc";
      data.in_id = this.selectedTag.id;
      switch (this.selectedAcao.value) {
        case "cadastroForm":
          data.out_action = "Form::assoc";
          if (!this.selectedForm) {
            this.$grToast.toast("Por favor, selecione um formulário", {
              title: "Automação de tags",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading =  false
            return;
          }
          data.out_id = this.selectedForm.id;
          break;
        case "removeForm":
          data.out_action = "Form::desassoc";
          if (!this.selectedForm) {
            this.$grToast.toast("Por favor, selecione um formulário", {
              title: "Automação de tags",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading =  false
            return;
          }
          data.out_id = this.selectedForm.id;
          break;
        case "webhook":
          data.out_action = "Webhook::tagAutomation";
          if (!this.url) {
            this.$grToast.toast("Por favor, insira uma url", {
              title: "Automação de tags",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading =  false
            return;
          }
          data.out_arguments = JSON.stringify(this.url);
          data.out_id = 0;
          break;
        case "associarProposta":
          if (!this.selectedProduto) {
            this.$grToast.toast("Por favor, selecione um produto", {
              title: "Automação de tags",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading =  false
            return;
          }
          data.out_action = "Produc::proposal";
          data.out_arguments = JSON.stringify({
            name: this.selectedProduto.name,
            product_id: this.selectedProduto.id,
            discount: this.valorPorcentagem,
            net_value: this.valor,
          });
          data.out_id = this.selectedProduto.id;
          break;
        case "associarCRM":
          data.out_action = "CRM::assoc";
          if (!this.pipeline) {
            this.$grToast.toast("Por favor, selecione um CRM", {
              title: "Automação de tags",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading =  false
            return;
          }

          data.out_id = this.pipeline.id;

          data.out_arguments = JSON.stringify({
            pipeline_id: this.pipeline.id,
            board_id: this.board.id,
          });
          this.loading =  false
          break;
        case "desassociarCRM":
          data.out_action = "CRM::desassoc";
          if (!this.pipeline) {
            this.$grToast.toast("Por favor, selecione um CRM", {
              title: "Automação de tags",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading =  false
            return;
          }

          data.out_id = this.pipeline.id;

          data.out_arguments = JSON.stringify({
            pipeline_id: this.pipeline.id,
            board_id: this.board && this.board.id ? this.board.id : null,
            remove_tag:this.removeTag
          });
          this.loading =  false
          break;
        case "associarTag":
          if (!this.selectedAssocTag) {
            this.$grToast.toast("Por favor, selecione uma tag!", {
              title: "Automação de tags",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading =  false
            return;
          }
          data.out_action = "Tag::assoc";
          data.out_id = this.selectedAssocTag.id
          break;
      }

      data.tenant_id = this.$store.getters.user.user.tenant_id;

      if (this.selectedAcao && this.selectedAcao.value == "webhook") {
        if (!this.isUrl) {
          this.$grToast.toast("Por favor, insira uma url valida", {
            title: "Automação de tags",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.loading =  false
          return;
        }
      }

      delete data.type;

      data = {...data, ...{type: this.selectedAutomationType && this.selectedAutomationType.type ? this.selectedAutomationType.type : null, schedule_date: !this.time ? null :  `${+this.days}|${this.time}`}};

      if (this.info == null) {
        serviceAutomacoes
          .create(data)
          .then((response) => {
            this.$grToast.toast("Automação criada com sucesso", {
              title: "Automação de tags",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$refs.sidebarAutomacao.hide();
            this.loading =  false
            this.$emit("add-automation");
          })
          .catch((error) => {
            this.loading =  false
            if (error.response.data.message == "This rule already exists") {
              this.$grToast.toast("Essa automação já está em uso", {
                title: "Automação de tags",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            }
          });
        return;
      }
      data.id = this.id;
      serviceAutomacoes
        .update(data)
        .then((response) => {
          this.loading =  false
          this.$grToast.toast("Automação atualizada com sucesso", {
            title: "Automação de tags",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$refs.sidebarAutomacao.hide();
          this.$emit("add-automation");
        })
        .catch((error) => {
          this.loading =  false
          if (error.response.data.message == "This rule already exists") {
            this.$grToast.toast("Essa automação já está em uso", {
              title: "Automação de tags",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        });
    },
    fetchPipelines(page = 1, query = "") {
      if (this.stops.includes("pipelines")) {
        return;
      }

      var data = {
        page: `${page}&order_by=title&order=ASC&search=${query}`,
      };

      servicePipeline
        .search(data)
        .then((response) => {
          var pipelines = response.data.map((element) => ({
            id: element.id,
            title: element.title,
            tags: element.tags,
          }));

          if (response.data.length < response.per_page) {
            this.stops.push("pipelines");
          }

          this.pipelines = this.pipelines.concat(pipelines);
        })
        .catch((err) => {
          // this.pipelines = [];
        });
    },
    debounceCrm: _.debounce(function (query) {
      if (query === "") {
        this.resetComponentCrm();
      } else {
        this.searchCrm = query;
        this.pageCrm = 1;
        this.pipelines = [];
        this.stops = [];
        this.fetchPipelines(this.pageCrm, query);
      }
    }, 500),
    resetComponentCrm() {
      this.pageCrm = 1;
      this.searchCrm = "";
      this.pipelines = [];
      this.stops = [];
      this.showComponentCrm = false;
      this.$nextTick(() => {
        this.showComponentCrm = true;
      });
    },
  },
  computed: {
    selectPipeline(){
      this.last_step = false;
      if(this.pipeline && this.selectedAcao.value == 'desassociarCRM'){
        this.last_step = true;
      }
      if(this.board && this.selectedAcao.value == 'associarCRM'){
        this.last_step = true 
      }
      return this.pipeline;
    },
    getArrayTags(){
      return this.tags.filter(x => x.id !== this.selectedTag.id);
    },
    getOptionsAcoes() {
        if (this.selectedQuando.value === 'entrar') {
            const exists = this.optionsAcoes.some(option => option.value === 'desassociarCRM');            
            if (!exists) {
                this.optionsAcoes = this.optionsAcoes.concat({ value: 'desassociarCRM', text: 'Desassociar CRM' });
            }
        } else {
            const index = this.optionsAcoes.findIndex(option => option.value === 'desassociarCRM');
            if (index !== -1) {
                this.optionsAcoes.splice(index, 1);
            }
        }
        return this.optionsAcoes;
    },
    descValue(){
      if(!this.selectedProduto) {
        return '0% - 0 reais'
      } 
      let value = Math.round((this.selectedProduto.product_value - this.valor) *  100) / 100
      return `${this.valorPorcentagem}% - ${value.toLocaleString('pt-br', {minimumFractionDigits: 2})} reais`
    },
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    valorPorcentagem(e) {
      if (isNaN(e)) {
        this.valorPorcentagem = 0;
      }
    },
    url(e) {
      this.urlValidate(e);
    },
    pipeline(e) {
      this.selectedPipe(e);
    },
  },
};
</script>

<style lang="scss">
#sidebarAutomacao {
  input[type="range"]::-webkit-slider-runnable-track {
    height: 5px;
    background: var(--white-medium);
    border-radius: 3px;
  }
  input[type="range"]::-webkit-slider-thumb {
    margin-top: -6px;
  }
  .titulo {
    font-size: 16px;
    font-weight: 600;
  }
  .b-sidebar-header {
    padding: 20px !important;
  }
  label {
    font-size: 14px !important;
  }
  .btn-automacao {
    margin: 15px auto;
    width: 100%;
  }
  width: 400px !important;
  background: white !important;
  .danger {
    font-size: 13px;
    color: red;
    font-weight: 500;
  }
  .separete {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin: 15px 0;
    .input-mensage {
      position: relative;
      .day-temp {
        position: absolute;
        color: #9a9a9a;
        font-size: 13px;
        left: 2px;
        bottom: -6px;
      }
    }
}
}
</style>
